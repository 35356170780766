body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

html, body, #root {
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

#root {
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: stretch;
          align-items: stretch;
  -webkit-align-content: stretch;
          align-content: stretch;
}

